import { AxiosInstance, AxiosResponse } from "axios";
import { IAPIResponse, INews } from "../interfaces";

const libraryService = (httpClient: AxiosInstance) => {
  return {
    getLibraries: async (): Promise<IAPIResponse<INews>> => {
      try {
        const res = await httpClient.get("/get/library");
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch libraries" };
      }
    },
    getLibraryById: async (pid): Promise<IAPIResponse<INews>> => {
        try {
          const res = await httpClient.get(`/get/lib/${pid}`);
          return res.data;
        } catch (error) {
          return { status: false, message: "Failed to fetch library" };
        }
      },
  };
};

export default libraryService;
