import { AxiosInstance, AxiosResponse } from "axios";
import {
  IAPIResponse,
  ICommonResponse,
  IUser,
  IClinicalProgram,
  IDoctor,
} from "../interfaces";

interface HospitalService {
  // ... other functions ...
  // addProgram: (data: IClinicalProgram) => Promise<IAPIResponse>;
}

const doctorService = (httpClient: AxiosInstance) => {
  return {
    addDoctor: async (data: IDoctor): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/doctor/create",
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Doctor Adding failed" };
      }
    },

    getDoctors: async (): Promise<IAPIResponse<IDoctor>> => {
      try {
        const res = await httpClient.get("/doctors");
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch doctors" };
      }
    },

    getSingleDoctor: async (id): Promise<IAPIResponse> => {
      try {
        const res = await httpClient.get(`/doctor/${id}`);
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch doctor" };
      }
    },

    updateDoctor: async (data: IDoctor): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.put(
          `/doctor/update/${data._id}`,
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Doctor Updation Failed" };
      }
    },

    deleteDoctor: async (doctorId: string): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.delete(
          `/doctor/delete/${doctorId}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Doctor Deletion Failed" };
      }
    },
  };
};

export default doctorService;
