import { AxiosInstance, AxiosResponse } from "axios";
import { IAPIResponse, ITestimonial } from "../interfaces";

const testimonialService = (httpClient: AxiosInstance) => {
    return {
        addTestimonial: async (data: ITestimonial): Promise<IAPIResponse> => {
            try {
                const res: AxiosResponse<IAPIResponse> = await httpClient.post(
                    "/ttmnl/create",
                    data
                );
                return res.data;
            } catch (error) {
                return { status: false, message: "Testimonials Adding failed" };
            }
        },

        getTestimonial: async (): Promise<IAPIResponse<ITestimonial>> => {
            try {
                const res = await httpClient.get("/ttmnl");
                return res.data;
            } catch (error) {
                return { status: false, message: "Failed to fetch testimonials" };
            }
        },

        getSingleTestimonial: async (id): Promise<IAPIResponse> => {
            try {
                const res = await httpClient.get(`/ttmnl/${id}`);
                return res.data;
            } catch (error) {
                return { status: false, message: "Failed to fetch testimonial" };
            }
        },

        updateTestimonial: async (data: ITestimonial): Promise<IAPIResponse> => {
            try {
                const res: AxiosResponse<IAPIResponse> = await httpClient.put(
                    `/ttmnl/update/${data._id}`,
                    data
                );
                return res.data;
            } catch (error) {
                return { status: false, message: "Testimonial Updation Failed" };
            }
        },

        deleteTestimonial: async (newsId: string): Promise<IAPIResponse> => {
            try {
                const res: AxiosResponse<IAPIResponse> = await httpClient.delete(
                    `/ttmnl/delete/${newsId}`
                );
                return res.data;
            } catch (error) {
                return { status: false, message: "Testimonial Deletion Failed" };
            }
        },
    };
};

export default testimonialService;
