import { AxiosInstance, AxiosResponse } from "axios";
import {
  IAPIResponse,
  ICommonResponse,
  IUser,
  IClinicalProgram,
} from "../interfaces";

interface ProgramService {
  // ... other functions ...
  // addProgram: (data: IClinicalProgram) => Promise<IAPIResponse>;
}

const ProgramService = (httpClient: AxiosInstance) => {
  return {
    addProgram: async (data: IClinicalProgram): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/program/create",
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Program Adding failed" };
      }
    },

    getPrograms: async (): Promise<IAPIResponse> => {
      try {
        const res = await httpClient.get("/programs");
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch programs" };
      }
    },

    getSingleProgram: async (id): Promise<IAPIResponse> => {
      try {
        const res = await httpClient.get(`/program/${id}`);
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch program" };
      }
    },

    updateProgram: async (data: any): Promise<IAPIResponse> => {
      try {
        console.log("PRGMDATA\t\t\t", data._id);
        const res: AxiosResponse<IAPIResponse> = await httpClient.put(
          `/program/update/${data._id}`,
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Program Updation Failed" };
      }
    },

    addMainImageService: async (
      data: any,
      owner: string
    ): Promise<IAPIResponse> => {
      try {
        console.log("ITEM_ID\t\t\t", owner);
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          `/add/main/image/${owner}`,
          data
        );
        return res.data;
      } catch (error) {
        console.log("File_Upload_Error||||\t\t\t", error);
        return { status: false, message: "File Upload Failed" };
      }
    },

    editMainImageService: async (
      data: any,
      owner: string
    ): Promise<IAPIResponse> => {
      try {
        console.log("ITEM_ID\t\t\t", owner);
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          `/edit/main/image/${owner}`,
          data
        );
        return res.data;
      } catch (error) {
        console.log("File_Upload_Error||||\t\t\t", error);
        return { status: false, message: "File Upload Failed" };
      }
    },

    addMainVideoService: async (
      data: any,
      owner: string
    ): Promise<IAPIResponse> => {
      try {
        console.log("ITEM_ID\t\t\t", owner);
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          `/add/main/video/${owner}`,
          data
        );
        return res.data;
      } catch (error) {
        console.log("File_Upload_Error||||\t\t\t", error);
        return { status: false, message: "File Upload Failed" };
      }
    },

    editMainVideoService: async (
      data: any,
      owner: string
    ): Promise<IAPIResponse> => {
      try {
        console.log("ITEM_ID\t\t\t", owner);
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          `/edit/main/video/${owner}`,
          data
        );
        return res.data;
      } catch (error) {
        console.log("File_Upload_Error||||\t\t\t", error);
        return { status: false, message: "File Upload Failed" };
      }
    },

    deleteProgram: async (programId: string): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.delete(
          `/program/delete/${programId}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Program Deletion Failed" };
      }
    },
  };
};

export default ProgramService;
