import { AxiosInstance, AxiosResponse } from "axios";
import { IAPIResponse, ICommonResponse } from "../interfaces";

const siteService = (httpClient: AxiosInstance) => {
    return {
        addBanner: async (
            data: {},
        ): Promise<IAPIResponse<ICommonResponse>> => {
            try {
                // console.log("Config",config);
                console.log("TEst:.......\n", data);
                const res: AxiosResponse<IAPIResponse<ICommonResponse>> =
                    await httpClient.post("/add/banner/image", data);
                return res.data;
            } catch (err) {
                console.log("err", err);
                return { status: false, message: "File upload failed" };
            }
        },
        getBanner: async (): Promise<IAPIResponse> => {
            try {
                const res: AxiosResponse<IAPIResponse> =
                    await httpClient.get('get/banner/image');
                return res.data;
            } catch (err) {
                return { status: false, message: "File upload failed" };
            }
        },
    };
};

export default siteService;
