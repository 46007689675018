import { AxiosInstance, AxiosResponse } from "axios";
import { IAPIResponse, IEvent } from "../interfaces";

const eventService = (httpClient: AxiosInstance) => {
  return {
    addEvent: async (data: IEvent): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/event/create",
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Event Adding failed" };
      }
    },

    getEvents: async (): Promise<IAPIResponse<IEvent>> => {
      try {
        const res = await httpClient.get("/events");
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch events" };
      }
    },

    getSingleEvent: async (id): Promise<IAPIResponse> => {
      try {
        const res = await httpClient.get(`/event/${id}`);
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch event" };
      }
    },

    updateEvent: async (data: IEvent): Promise<IAPIResponse> => {
      try {
        console.log("DATAEVENT\t\t\t",data)
        const res: AxiosResponse<IAPIResponse> = await httpClient.put(
          `/event/edit/${data._id}`,
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Event Updation Failed" };
      }
    },

    deleteEvent: async (eventId: string): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.delete(
          `/event/delete/${eventId}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Event Deletion Failed" };
      }
    },
  };
};

export default eventService;
