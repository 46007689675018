import { AxiosInstance, AxiosResponse } from "axios";
import { IAPIResponse, INews } from "../interfaces";

const newsService = (httpClient: AxiosInstance) => {
  return {
    addNews: async (data: INews): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/news/create",
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "News Adding failed" };
      }
    },

    getNews: async (): Promise<IAPIResponse<INews>> => {
      try {
        const res = await httpClient.get("/news");
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch news" };
      }
    },

    getSingleNews: async (id): Promise<IAPIResponse> => {
      try {
        const res = await httpClient.get(`/news/${id}`);
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch news" };
      }
    },

    updateNews: async (data: INews): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.put(
          `/news/update/${data._id}`,
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "News Updation Failed" };
      }
    },

    deleteNews: async (newsId: string): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.delete(
          `/news/delete/${newsId}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "News Deletion Failed" };
      }
    },
  };
};

export default newsService;
