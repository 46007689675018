/* eslint-disable react-hooks/exhaustive-deps */
import { lazy, useCallback } from "react";
import { Route, Routes } from "react-router-dom";
import Root from "../components/Root";

import { apiConfig } from "../config/api-config";
import { IAuth } from "../interfaces";
import { IRootState } from "../interfaces/redux/store";
import { userRoutes } from "./user-routes";
import { adminRoutes } from "./admin-routes";
import { PrivateRoute } from "./private-routes";
import { useSelector } from "react-redux";
import { Role } from "../enum";
import Template from "../components/template/Template";
import Admins from "../pages/admins/Admins";
import SuperAdmins from "../pages/super-admins/superAdmins";
// import Search from "../pages/search/Search";

const UserSignIn = lazy(() => import("../pages/sign-in/SignIn"));
const AdminSignIn = lazy(() => import("../pages/admin/sign-in/SignIn"));
const FourNotFour = lazy(() => import("../pages/FourNotFour"));
const Home = lazy(() => import("../pages/home/home"));
// const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));
const News = lazy(() => import("../pages/news/News"));
const Library = lazy(() => import("../pages/library/library"));
const Events = lazy(() => import("../pages/events/Events"));
const ClinicalPrograms = lazy(
  () => import("../pages/clinicalprograms/ClinicalPrograms")
);
// const Shared = lazy(() => import("../pages/shared/Shared"));
const StudentManagement = lazy(
  () => import("../pages/studentmanagement/StudentManagement")
);
const HospitalManagement = lazy(
  () => import("../pages/hospitalmanagement/HospitalManagement")
);
// const HospitalTable = lazy(
//   () => import("../pages/hospitalmanagement/hospitalTable")
// );
const DoctorMangement = lazy(() => import("../pages/doctor/DoctorManagement"));
const SiteManagement = lazy(
  () => import("../pages/sitemanagement/SiteManagement")
);
const Testimonials = lazy(() => import("../pages/testimonials/Testimonials"));

// const Activities = lazy(() => import("../pages/activity/Activities"));
// const Notifications = lazy(() => import("../pages/notification/Notification"));
// const Trash = lazy(() => import("../pages/trash/Trash"));
const EditProfile = lazy(() => import("../pages/edit-profile/EditProfile"));
const Files = lazy(() => import("../pages/files/files"));

const Routers = () => {
  const auth = useSelector<IRootState, IAuth>(({ auth }) => auth.value);
  // const getRole = () => {
  //   switch (auth.data?.role) {
  //     case Role.ADMIN_ID:
  //       return Role.ADMIN_ID;

  //     case Role.USERS_ID:
  //     default:
  //       return Role.USERS_ID;
  //   }
  // };

  // const getRoutes = useCallback(() => {
  //   const role = getRole();
  //   if (auth.data && role === Role.ADMIN) {
  //     return adminRoutes.map((route) => ({ ...route, role: role }));
  //   }

  //   if (auth.data && auth.data.role === Role.USER) {
  //     return userRoutes.map((route) => ({ ...route, role: Role.USER }));
  //   }

  //   return [];
  // }, [auth]);

  return (
    <>
      <Routes>
        {/* <Route
          path={`${apiConfig.basePath}/`}
          element={
            <Root>
              <PrivateRoute isIndex />
            </Root>
          }
        />

        {getRoutes().map(({ path, component, role }, index) => (
          <Route
            key={index}
            path={`${apiConfig.basePath}${path}`}
            element={
              <Template>
                <PrivateRoute component={component} access={role} />
              </Template>
            }
          />
        ))} */}
        <Route
          path={`${apiConfig.basePath}/sign-in`}
          element={<UserSignIn />}
        />
        <Route
          path={`${apiConfig.basePath}/`}
          element={
            <Template>
              <></>
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}`}
          element={
            <Template>
              <></>
            </Template>
          }
        />
        {/* <Route
          path={`${apiConfig.basePath}/dashboard`}
          element={
            <Template>
              <Dashboard />
            </Template>
          }
        /> */}
        <Route
          path={`${apiConfig.basePath}/news`}
          element={
            <Template>
              <News />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/news/:id`}
          element={
            <Template>
              <News />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/news/super-admin/:superAdminId`}
          element={
            <Template>
              <News />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/news/admin/:adminId`}
          element={
            <Template>
              <News />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/news/user/:userId`}
          element={
            <Template>
              <News />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/news/user/:userId/:recordId`}
          element={
            <Template>
              <News />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/studentmanagement`}
          element={
            <Template>
              <StudentManagement />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/admins`}
          element={
            <Template>
              <Admins />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/super-admins`}
          element={
            <Template>
              <SuperAdmins />
            </Template>
          }
        />

        <Route
          path={`${apiConfig.basePath}/edit-profile`}
          element={
            <Template>
              <EditProfile />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/events`}
          element={
            <Template>
              <Events />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/clinicalprograms`}
          element={
            <Template>
              <ClinicalPrograms />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/hospitalmanagement`}
          element={
            <Template>
              <HospitalManagement />
            </Template>
          }
        />
         {/* <Route
          path={`${apiConfig.basePath}/hospitaltable`}
          element={
            <Template>
              <HospitalTable />
            </Template>
          }
        /> */}
        <Route
          path={`${apiConfig.basePath}/doctormanagement`}
          element={
            <Template>
              <DoctorMangement />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/library`}
          element={
            <Template>
              <Library />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/sitemanagement`}
          element={
            <Template>
              <SiteManagement />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/testimonials`}
          element={
            <Template>
              <Testimonials />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/files/:pid`}
          element={
            <Template>
              <Files />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/files/:pid/:id`}
          element={
            <Template>
              <Files />
            </Template>
          }
        />

        {/* <Route
          path={`${apiConfig.basePath}/`}
          element={<UserSignIn />}
        /> */}
        {/* <Route
          path={`${apiConfig.basePath}/admin/sign-in/`}
          element={<AdminSignIn />}
        /> */}

        <Route path="*" element={<FourNotFour />} />
      </Routes>
    </>
  );
};

export default Routers;
