import {
  BiHomeAlt,
  BiFolder,
  BiShareAlt,
  BiUser,
  BiHistory,
  BiTrash,
  BiNews,
  BiLibrary,
} from "react-icons/bi";
import { CgComment } from "react-icons/cg";
import { FaUser } from "react-icons/fa";
import { MdOutlineLocalHospital } from "react-icons/md";
import { CiImageOn } from "react-icons/ci";
import { PiUserLight, PiUserPlusLight, PiStudentLight } from "react-icons/pi";
import { LiaHospitalSymbolSolid } from "react-icons/lia";
import { BsCalendar4Event } from "react-icons/bs";
import { FaUserGraduate } from "react-icons/fa";
import { RiHospitalLine } from "react-icons/ri";
import { IoImageOutline } from "react-icons/io5";
import { Location } from "react-router-dom";
import { ISideBarOption } from "../interfaces";
import { validateToken } from "./auth-utils";

export const sideBarOptions: ISideBarOption[] = [
  {
    title: "Student Management",
    icon: PiStudentLight,
    url: "/studentmanagement",
    name: "users",
  },
  {
    title: "Doctor Management",
    icon: FaUser,
    url: "/doctormanagement",
    name: "doctormanagement",
  },
  {
    title: "Hospital Management",
    icon: LiaHospitalSymbolSolid,
    url: "/hospitalmanagement",
    name: "hospitalmanagement",
  },
  {
    title: "Clinical Programs",
    icon: RiHospitalLine,
    url: "/clinicalprograms",
    name: "clinicalprograms",
  },
  { title: "Library", icon: BiLibrary, url: "/library", name: "library" },
  { title: "Events", icon: BsCalendar4Event, url: "/events", name: "events" },
  { title: "News", icon: BiNews, url: "/news", name: "news" },
  {
    title: "Testimonials",
    icon: CgComment,
    url: "/testimonials",
    name: "testimonials",
  },
  {
    title: "Banner Mangement",
    icon: CiImageOn,
    url: "/sitemanagement",
    name: "banner",
  },
];
export const allowEditTypes = [
  "ppt",
  "pptx",
  "pps",
  "ppsx",
  "odp",
  "sxi",
  "docx",
  "xls",
  "xlsx",
  "zdoc",
  "zsheet",
  " xlsx",
  "xls",
  "ods",
  " csv",
  "tsv",
  "doc",
];

export const superAdminMenuItems = [
  // "dashboard",
  "news",
  "users",
  "admins",
  "superAdmins",
  "events",
  "clinicalprograms",
  "hospitalmanagement",
  "banner",
  "doctormanagement",
  "library",
  "testimonials",
  // "activities",
  // "trash",
];
export const adminMenuItems = [
  // "dashboard",
  "news",
  "shared",
  "users",
  "events",
  "clinicalprograms",
  "hospitalmanagement",
  "banner",
  // "activities",
  // "trash",
];
export const userMenuItems = [
  "news",
  "shared",
  "trash",
  "events",
  "clinicalprograms",
  "hospitalmanagement",
  "banner",
];
// export const userMenuItems = ['files', 'shared', 'profile', 'recent', 'trash'];

export const getTitle = (location: Location) =>
  sideBarOptions.find((d) =>
    location.pathname.toLowerCase().includes(d.url.toLowerCase())
  )?.title || "superAdmins";

export const ROLES = {
  SUPER_ADMIN: "super_admin",
  ADMIN: "admin",
  USER: "user",
};
export const ROLE_TYPES = {
  super_admin: "Super Admin",
  admin: "Admin",
  user: "User",
};

export const SUPER_ADMIN_ROLE = "super_admin";
export const ADMIN_ROLE = "admin";
export const USER_ROLE = "user";

export const RECORD_PERMISSIONS_TYPES = {
  VIEW: "view",
  EDIT: "edit",
  OWNER: "owner",
};
export const RECORD_PERMISSIONS = [
  RECORD_PERMISSIONS_TYPES.VIEW,
  RECORD_PERMISSIONS_TYPES.EDIT,
];
export const imageExtensions = ["jpg", "jpeg", "png", "gif", "webp", "svg"];
