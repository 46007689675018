import { AxiosInstance, AxiosResponse } from "axios";
import {
  IAPIResponse,
  ICommonResponse,
  IUser,
  IClinicalProgram,
  IHospital,
} from "../interfaces";

interface HospitalService {
  // ... other functions ...
  // addProgram: (data: IClinicalProgram) => Promise<IAPIResponse>;
}

const hospitalService = (httpClient: AxiosInstance) => {
  return {
    addHospital: async (data: any): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/hospital/create",
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Hospital Adding failed" };
      }
    },

    getHospitals: async (): Promise<IAPIResponse<IHospital>> => {
      try {
        const res = await httpClient.get("/hospitals");
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch hospital" };
      }
    },

    getSingleHospital: async (id): Promise<IAPIResponse> => {
      try {
        const res = await httpClient.get(`/hospital/${id}`);
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch hospital" };
      }
    },

    updateHospital: async (data: IHospital): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.put(
          `/hospital/edit/${data._id}`,
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Hospital Updation Failed" };
      }
    },

    deleteHospital: async (hospitalId: string): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.delete(
          `/hospital/delete/${hospitalId}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Hospital Deletion Failed" };
      }
    },
  };
};

export default hospitalService;
